import React, { useState } from 'react';
import NewsArticle from '../components/newsArticle';
import articlesData from '../data/articles.json';

interface Article {
    title: string;
    photos: string[];
    sources: string;
    body: string;
    date: string; // Utiliser `string` au lieu de `Date` si les dates sont des chaînes JSON
}

const Home = () => {
    // Accès direct aux articles dans articlesData
    const [articles, setArticles] = useState<Article[]>(articlesData.articles);

    return (
        <div>
            {articles.map((article, index) => (
                <NewsArticle key={index} article={article} />
            ))}
        </div>
    );
};

export default Home;
