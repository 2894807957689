import DateTime from 'react-datetime';
import React, { useState } from 'react';
import matchsData from '../data/tournament.json';
import MatchCapsule from '../components/matchCapsule';

interface Match {
    teamAName: string;
    teamALogo: string;
    teamAScore: number | null;
    teamBName: string;
    teamBLogo: string;
    teamBScore: number | null;
    matchDate: string;
    matchType: string;
}

const Planning = () => {
    const [matchs, setMatchs] = useState<Match[]>(matchsData.matchs);

    return (
        <div className="w-full min-w-[1000px] overflow-x-auto overflow-y-hidden p-6 bg-raisinBlack">
            <div className="grid grid-cols-4 gap-4">
                <div className="">             
                <h1 className="w-full text-center py-3 text-2xl bold">Qualifications</h1>    
                    <div className="items-center justify-center h-full">
                        {matchs.map((match, index) => (
                            <>{match.matchType == "qualification" && ( <MatchCapsule match={match} />)}</>                        
                        ))}
                    </div>
                </div>
                <div className="">     
                    <h1 className="w-full text-center py-3 text-2xl bold">Quarts de finale</h1>
                    <div className="w-full grid gap-20 pt-16">
                        {matchs.map((match, index) => (
                            <>{match.matchType == "quart" && ( <MatchCapsule match={match} />)}</>                        
                        ))}
                    </div>
                </div>
                <div className="">     
                <h1 className="w-full text-center py-3 text-2xl bold">Demis finale</h1>
                <div className="w-full grid gap-72 pt-44">
                        {matchs.map((match, index) => (
                            <>{match.matchType == "demi" && ( <MatchCapsule match={match} />)}</>                        
                        ))}
                    </div>
                </div>
                <div className="">     
                <h1 className="w-full text-center py-3 text-2xl bold">Finale</h1>
                <div className="w-full pt-96">
                        {matchs.map((match, index) => (
                            <>{match.matchType == "finale" && ( <MatchCapsule match={match} />)}</>                        
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Planning;