import { Link } from "react-router-dom";

const Sponsor = () => {

    return (
        <div className="w-full p-6 bg-raisinBlack">

            <div className="w-full flex items-center justify-center mt-4 mb-8">
                <h1 className="w-full text-bold text-2xl text-center">Nos partenaires</h1>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <div>
                    <img className="h-auto max-w-full rounded-lg scale-95 hover:scale-100" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg scale-95 hover:scale-100" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg scale-95 hover:scale-100" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg scale-95 hover:scale-100" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg scale-95 hover:scale-100" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-4.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg scale-95 hover:scale-100" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-5.jpg" alt="" />
                </div>           
            </div>

            <div className="w-full flex items-center justify-center mt-4">
                <div className="flex flex-col items-center">
                    <h1 className="w-full text-bold text-2xl text-center">Vous souhaitez devenir partenaire ?</h1>
                    <button
                        onClick={(e) => {
                            const email = "contact@hardpushmid.com";
                            navigator.clipboard.writeText(email).then(function() {
                            alert("contact@hardpushmid.com : Adresse e-mail copiée dans le presse-papier !");
                            }, function(err) {
                            alert("Erreur lors de la copie de l'adresse e-mail");
                            console.error("Erreur de copie : ", err);
                            });
                        }}
                        className="bg-mediumStateBlue px-4 py-2 rounded mt-2"
                    >
                        Contactez nous
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sponsor;