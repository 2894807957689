import React, { useState } from 'react';

interface Article {
    title: string;
    photos: string[];
    sources: string;
    body: string;
    date: string; // Utiliser `string` pour correspondre au format JSON
}

interface NewsArticleProps {
    article: Article;
}

const NewsArticle: React.FC<NewsArticleProps> = ({ article }) => {
    // État pour suivre l'index de la photo actuellement affichée
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    // Fonction pour passer à la photo suivante
    const nextPhoto = () => {
        setCurrentPhotoIndex((prevIndex) =>
            prevIndex === article.photos.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Fonction pour revenir à la photo précédente
    const prevPhoto = () => {
        setCurrentPhotoIndex((prevIndex) =>
            prevIndex === 0 ? article.photos.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="bg-raisinBlack w-full md:w-[50%] m-auto my-6 p-6 overflow-hidden">
            <h1 className="text-2xl font-bold text-white">{article.title}</h1>
            <p className="text-verdigris text-xs italic">{article.date}</p>

            {article.photos.length > 0 && (
                <div className="relative mt-4">
                    {/* Image actuelle */}
                    <img
                        src={article.photos[currentPhotoIndex]}
                        alt="Article"
                        className="m-auto rounded-md"
                    />
                    
                    {/* Boutons de navigation */}
                    {article.photos.length > 1 && (
                        <>
                            {/* Bouton Précédent */}
                            <button
                                onClick={prevPhoto}
                                className="h-36 w-10 absolute top-1/2 left-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-l hover:bg-opacity-75"
                            >
                                &lt;
                            </button>

                            {/* Bouton Suivant */}
                            <button
                                onClick={nextPhoto}
                                className="h-36 w-10  absolute top-1/2 right-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-r hover:bg-opacity-75"
                            >
                                &gt;
                            </button>
                        </>
                    )}
                </div>
            )}
        <div className='text-verdigris mb-4 w-full text-center italic text-sm'>
            source(s): {article.sources}
        </div>
        <div
            className="text-white"
            dangerouslySetInnerHTML={{ __html: article.body }}
        ></div>

        </div>
    );
};

export default NewsArticle;
