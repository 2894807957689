import React, { useState } from 'react';

interface Match {
    teamAName: string;
    teamALogo: string;
    teamAScore: number | null;
    teamBName: string;
    teamBLogo: string;
    teamBScore: number | null;
    matchDate: string;
    matchType: string;
}
interface MatchProps {
    match: Match;
}

const MatchCapsule: React.FC<MatchProps> = ({ match }) => {
    
    return (
        <div className="grid grid-rows-2 border my-6 w-full">
            <div className={`flex border items-center justify-center ${match.teamAScore === 3 ? 'bg-syracuseRedOrange' : ''}`}><img className="w-10 h-10 bg-white p-1" src={match.teamALogo} /><p className="w-full px-2">{match.teamAName}</p><p className="w-[10%]">{match.teamAScore}</p></div>
            <div className={`flex border items-center justify-center ${match.teamBScore === 3 ? 'bg-syracuseRedOrange' : ''}`}><img className="w-10 h-10 bg-white p-1" src={match.teamBLogo} /><p className="w-full px-2">{match.teamBName}</p><p className="w-[10%]">{match.teamBScore}</p></div>
        </div>
    );
};

export default MatchCapsule;
