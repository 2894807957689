const Contact = () => {

    return (
        <div className="w-full p-6 bg-raisinBlack">
            <div>
                <h1 className="w-full font-bold text-2xl">Qui sommes-nous ?</h1>
                <p className="mt-2">
                    L'association Push Mid est un organisme visant à organiser des événements publics autour de League of Legends et l'Esport. 
                    Cela passe par des 
                    <span className="underline cursor-pointer relative ml-1 group">
                        Viewing Party
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-48 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Une Viewing Party est un événement où l'on se rassemble entre spectateurs pour regarder des matchs qui ne sont pas joués sur place.
                        </div>
                    </span>, 
                    des  
                    <span className="underline cursor-pointer relative ml-1 group">
                        Open Sessions
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-48 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Une Open Session est un ensemble de matchs vous permettant de trouver des équipiers dans l'objectif de créer une équipe pour les tournois.
                        </div>
                    </span> ainsi que des 
                    <span className="underline cursor-pointer relative ml-1 group">
                        Tournois
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-48 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Un tournoi est un ensemble de matchs éliminatoires visant à déterminer la meilleure équipe.
                        </div>
                    </span>.
                </p>
            </div>

            <div className="md:grid md:grid-cols-3 gap-4 mt-4">
                <div className="border border-red-200 p-4">
                    <h1 className="w-full font-bold text-2xl mx-4">Spectateurs</h1>
                    <img className="w-full mt-4" src="https://plus.unsplash.com/premium_photo-1723489402380-b46d1c07a0c1?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <p className="p-3 mt-2 text-justify">Venez vivre des moments intenses avec nous lors de nos prochains tournois ! Que ce soit en direct dans notre salle ou depuis votre canapé, on vous promet du sport, du sang et des larmes. Venez encourager votre équipe favorite et partager des moments forts avec d’autres fans. On vous attend pour une expérience dont seuls les fans Français en ont le secret!</p>
                    <p className="p-3 mt-2 text-justify">Suivez nous sur nos réseaux pour connaitre la date le lieu de notre prochain évènement !</p>             
                    <div className="grid grid-cols-4 gap-2 md:gap-6 px-4 md:px-8 mt-4">
                    <a href="https://discord.gg/NTSkqPNKNq" target="_blank" rel="noopener noreferrer"><img src="./discord.png" /></a>
                    <a href="https://www.instagram.com/hardpushmid/" target="_blank" rel="noopener noreferrer"><img src="./instagram.png" /></a>
                    <a href="https://www.facebook.com/HardPushMid" target="_blank" rel="noopener noreferrer"><img src="./facebook.png" /></a>
                    <a href="https://x.com/HardPushMid" target="_blank" rel="noopener noreferrer"><img src="./twitter.png" /></a>
                    </div>
                </div>
                <div>
                    <div className="border border-blue-200 p-4">
                        <h1 className="w-full font-bold text-2xl mx-4">Joueurs</h1>
                        <img className="w-full mt-4" src="https://plus.unsplash.com/premium_photo-1682147914939-a30af3add7cd?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        <p className="p-3 mt-2 text-justify">Vous êtes joueurs et souhaitez rencontrer des joueurs de votre niveau afin de monter une équipe ? Aucun problème, vous pouvez venir à lors de nos 
                        <span className="underline cursor-pointer relative ml-1 group">
                        Open Sessions
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-48 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Une Open Session est un ensemble de matchs vous permettant de trouver des équipiers dans l'objectif de créer une équipe pour les tournois.
                        </div>
                        </span> ou bien rejoindre notre Discord pour rencontrer vos futurs coéquipiers !</p>
                    <div className="flex items-center justify-center">
                        <a href="https://discord.gg/NTSkqPNKNq" target="_blank" rel="noopener noreferrer"><img className="w-20" src="./discord.png" /></a>
                    </div>
                    </div>
                    <div className="border border-blue-200 p-4 mt-4">
                        <h1 className="w-full font-bold text-2xl mx-4">Equipes</h1>
                        <img className="w-full mt-4" src="https://plus.unsplash.com/premium_photo-1683141331949-64810cfc4ca3?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        <p className="p-3 mt-2 text-justify">Vous avez trouvé vos coéquipiers ? Super ! Push Mid vous propose un ensemble de services pour votre équipe alors profitez en !</p>
                        <ul className="list-disc pl-5 space-y-2">
                            <li className="">Création d'une identité visuelle ( Logo )</li>
                            <li className="">Service d'analyse et de Coaching</li>
                            <li className="">Mise en relation avec d'autres équipes pour des 
                            <span className="underline cursor-pointer relative ml-1 group">
                            Scrims
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-48 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Match d'entraînement entre deux équipes officielles.
                            </div>
                            </span> 
                            </li>
                            <li className="">Mise en avant de votre équipe sur nos réseaux</li>
                            <li className="">Accès aux Tournois</li>
                        </ul> 
                    </div>
                </div>
                <div className="border border-green-200 p-4">
                    <h1 className="w-full font-bold text-2xl mx-4">Bénévoles</h1>
                    <img className="w-full mt-4" src="https://images.unsplash.com/photo-1558008322-dd333084be9d?q=80&w=3731&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <p className="p-3 mt-2 text-justify">Notre association a besoin de bénévoles pour organiser nos évènements. Rejoingnez nous en nous contactant directement par mail ou par Discord. Nous recherchons activement :</p>
                        <ul className="list-disc pl-5 space-y-2">
                            <li className="">Coachs / Analystes</li>
                            <li className="">Commentateur Esport</li>
                            <li className="">
                            <span className="underline cursor-pointer relative ml-1 group">
                            Staff Polyvalent
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 w-48 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Vous pouvez litteralement tout faire ! De la régie, de l'orga, de la communication, ...
                            </div>
                            </span> 
                            </li>
                            <li className="">Vous avez une idée en tête ? Parlez nous en !</li>
                        </ul> 
                        <div className="flex items-center justify-center mt-4">
                        <a href="https://discord.gg/NTSkqPNKNq" target="_blank" rel="noopener noreferrer"><img className="w-20 mx-4" src="./discord.png" /></a>
                        <button
                            onClick={(e) => {
                                const email = "contact@hardpushmid.com";
                                navigator.clipboard.writeText(email).then(function() {
                                alert("contact@hardpushmid.com : Adresse e-mail copiée dans le presse-papier !");
                                }, function(err) {
                                alert("Erreur lors de la copie de l'adresse e-mail");
                                console.error("Erreur de copie : ", err);
                                });
                            }}
                        ><img className="w-20 mx-4" src="./communication.png" /></button>
                        </div>
                </div>
            </div>
        </div>
    );
};



export default Contact;